import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Assert } from '../interface/Assert';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { AssetSearchCriteriaDTO } from '../model/asset-search-criteria-dto';
interface Response {
  content: any[];
  pageable: any; // Use a more specific type if possible
  last: boolean;
  totalElements: number;
  totalPages: number;
  first: boolean;
  size: number;
  number: number;
  sort: any; // Use a more specific type if possible
  numberOfElements: number;
  empty: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class AssertService {

  private _baseUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) { }
  //form not posting
  private dataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public data$: Observable<any> = this.dataSubject.asObservable();


  getAssert(stationId:number): Observable<Response> {
    if(stationId===undefined){
      return this.http.get<Response>(`${environment.apiUrl}/assert/getByStation`);
    }
    return this.http.get<Response>(`${environment.apiUrl}/assert/getByStation?stationId=${stationId}`);
  }

  getAssertWithStationId(stationId: number,page:number,size:number,query:string): Observable<Response> {
    let params = new HttpParams().set('stationId', stationId.toString()).set('query',query?query:'').append('page',page).append('size',size);
    return this.http.get<Response>(`${environment.apiUrl}/assert/getByStation`, { params: params });
  }
  
  getAllBydescript(): Observable<any>{

    return this.http.get<Response>(`${environment.apiUrl}/assert/getAllBydescript`);
  }
  



  checkAssets(stationId: number): Observable<Response> {
    return this.http.post<Response>(`${environment.apiUrl}/station/check?stationId=${stationId}`,"");
  }
  
  getStations(): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/station/getAll`);
  }

  getProducts(): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/api/v1/products/all`);
  }

  getOffices(stationId: number): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/api/v1/station/location/all?stationId=${stationId}`);
  }

  addAsserts(
    formData: any
  ): Observable<Assert | HttpErrorResponse> {
    return this.http.post<Assert>(`${environment.apiUrl}/assert/create`, formData)
    .pipe(catchError((error) => {
      // Check if the error is a 403 Forbidden error
      if (error.status === 403) {
        // Extract the error message from the error response
        const errorMessage = error.error?.message || 'Forbidden: You do not have permission.';
        // Convert the error to an Observable so it can be subscribed to
        return throwError(errorMessage);
      } else {
        // For other types of errors, rethrow them
        return throwError(error);
      }
    })
    );;
  }
  addOffice
  (
    formData: any,stationId:number
  ): Observable<Assert | HttpErrorResponse> {
    return this.http.post<Assert>(`${environment.apiUrl}/api/v1/station/location/add?stationId=${stationId}`, formData)
    .pipe(catchError((error) => {
      // Check if the error is a 403 Forbidden error
      if (error.status === 403) {
        // Extract the error message from the error response
        const errorMessage = error.error?.message || 'Forbidden: You do not have permission.';
        // Convert the error to an Observable so it can be subscribed to
        return throwError(errorMessage);
      } else {
        // For other types of errors, rethrow them
        return throwError(error);
      }
    })
    );;
  }
  addInspection(formData: any, id:any): Observable<any | HttpErrorResponse> {
    return this.http.post<any>(`${environment.apiUrl}/assert/addtoassert/${id}`, formData);
  }
  addAssetList(formData: any): Observable<any | HttpErrorResponse> {
    return this.http.post<any>(`${environment.apiUrl}/api/v1/products/create`, formData);
  }


  public updateAsserts(FormData: any, id: any): Observable<HttpErrorResponse> {
    return this.http.post<any>(`${environment.apiUrl}/Asserts/update/${id}`, FormData);

  }


  getParentAsset(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/FixedAsserts/getAll`);
  }
  getPAsset(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/Asserts/getAll`);
  }

  getFilesystem() {
    return Promise.resolve(this.getTreeNodesData());
  }


  searchAssets(body:AssetSearchCriteriaDTO):Observable<any[]>{
    return this.http.post<any[]>(`${environment.apiUrl}/assert/search-assets`,body)
  }

  getTreeNodesData() {
    return [
      {
        key: '0',
        label: 'Documents',
        data: 'Documents Folder',
        icon: 'pi pi-fw pi-inbox',
        children: [
          {
            key: '0-0',
            label: 'Work',
            data: 'Work Folder',
            icon: 'pi pi-fw pi-cog',
            children: [
              {
                key: '0-0-0',
                label: 'Expenses.doc',
                icon: 'pi pi-fw pi-file',
                data: 'Expenses Document',
              },
              {
                key: '0-0-1',
                label: 'Resume.doc',
                icon: 'pi pi-fw pi-file',
                data: 'Resume Document',
              },
            ],
          },
          {
            key: '0-1',
            label: 'Home',
            data: 'Home Folder',
            icon: 'pi pi-fw pi-home',
            children: [
              {
                key: '0-1-0',
                label: 'Invoices.txt',
                icon: 'pi pi-fw pi-file',
                data: 'Invoices for this month',
              },
            ],
          },
        ],
      },
      //remove this we
      {
        key: '1',
        label: 'Events',
        data: 'Events Folder',
        icon: 'pi pi-fw pi-calendar',
        children: [
          {
            key: '1-0',
            label: 'Meeting',
            icon: 'pi pi-fw pi-calendar-plus',
            data: 'Meeting',
          },
          {
            key: '1-1',
            label: 'Product Launch',
            icon: 'pi pi-fw pi-calendar-plus',
            data: 'Product Launch',
          },
          {
            key: '1-2',
            label: 'Report Review',
            icon: 'pi pi-fw pi-calendar-plus',
            data: 'Report Review',
          },
        ],
      },
      {
        key: '2',
        label: 'Movies',
        data: 'Movies Folder',
        icon: 'pi pi-fw pi-star-fill',
        children: [
          {
            key: '2-0',
            icon: 'pi pi-fw pi-star-fill',
            label: 'Al Pacino',
            data: 'Pacino Movies',
            children: [
              {
                key: '2-0-0',
                label: 'Scarface',
                icon: 'pi pi-fw pi-video',
                data: 'Scarface Movie',
              },
              {
                key: '2-0-1',
                label: 'Serpico',
                icon: 'pi pi-fw pi-video',
                data: 'Serpico Movie',
              },
            ],
          },
          {
            key: '2-1',
            label: 'Robert De Niro',
            icon: 'pi pi-fw pi-star-fill',
            data: 'De Niro Movies',
            children: [
              {
                key: '2-1-0',
                label: 'Goodfellas',
                icon: 'pi pi-fw pi-video',
                data: 'Goodfellas Movie',
              },
              {
                key: '2-1-1',
                label: 'Untouchables',
                icon: 'pi pi-fw pi-video',
                data: 'Untouchables Movie',
              },
            ],
          },
        ],
      },
    ];
  }

  downloadPdf(stationId:number,searchQuery:string): Observable<Blob> {
    return this.http.get(`${environment.apiUrl}/api/v1/jasper/pdf/station/assets?stationId=${stationId}&query=${searchQuery==undefined?"":searchQuery}`, { responseType: 'blob' });
  }
}
