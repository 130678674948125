import { Component, OnInit, ViewChild } from '@angular/core';
import { MasterregisterService } from '../masterregister.service';
import { Masterregister } from '../masterregister';

@Component({
  selector: 'app-masterregister',
  templateUrl: './masterregister.component.html',
  styleUrls: ['./masterregister.component.css']
})
export class MasterregisterComponent   implements OnInit
{

  masters: Masterregister[] = [];
  constructor( private masterregisterService: MasterregisterService) {}
  
  ngOnInit() {
    this.masterregisterService.getMaster().subscribe(masters => {
      this.masters = masters;
    });
  }

  cancelModal() {
   
  }



}
