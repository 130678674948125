<p-toast></p-toast>
<app-sidebar></app-sidebar>
<section class="home-section">


  <!-- nav bar -->
  <!-- <nav class="navbar navbar-expand-md breadcrumb">
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item-left">

        </li>

        <li class="nav-item move-right mr-3">
          <a class="nav-link" data-toggle="tab" href="#profile">
            Welcome, <i class="fa fa-user"></i>
          </a>
        </li>
      </ul>

    </div>
  </nav> -->


  <nav class="bg-white shadow-sm mb-4 p-4 flex justify-between items-center">
    <div class="flex items-center">
      <button class="text-gray-500 hover:text-gray-700 mr-4">
        <i class="fas fa-bars"></i>
      </button>
      <h1 class="text-xl font-semibold">Dashboard</h1>
    </div>
    <div class="flex items-center">
      <div class="relative mr-4">
        <input  type="text" placeholder="Search..."
          class="bg-gray-100 rounded-full py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500">
        <i class="fas fa-search absolute right-3 top-3 text-gray-400"></i>
      </div>
      <div class="flex items-center">
        <i class="fas fa-chevron-down mr-2"></i>
        <a href="./login" class="text-blue-600 hover:text-blue-800">Logout</a>
      </div>
    </div>
  </nav>

  //base level modal

  <div class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
    <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
      Add Requared Level
    </h3>
    <button type="button"
      class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
      (click)="cancelModal()">
      <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clip-rule="evenodd"></path>
      </svg>
      <span class="sr-only">Close modal</span>
    </button>
  </div>






  
  <div class="home-content">
    <div class="container-fluid">
  
  <p-table [value]="masters" [rowsPerPageOptions]="[5, 10, 20]">
    <ng-template pTemplate="header">
      <tr>
        <!-- <th>ID</th> -->
        <th>Asset Description</th>
        <th>Requared Level</th>
        <th>Current Year</th>
        <th>Previous Year</th>
        <th>Surplus</th>
        <th>Deficiency</th>
        <th>Remarks</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-check>
      <tr>
        <!-- <td>{{ check.id }}</td> -->
        <td>{{ check.name }}</td>
        <td>{{ check.requiredLevel }}</td>
        <td>{{ check.currentYearTotal }}</td>
        <td>{{ check.previousYearTotal }}</td>
        <td>{{ (check.currentYearTotal - check.previousYearTotal) < 0 ? '-' : (check.currentYearTotal - check.previousYearTotal) }}</td>
        <td>{{ (check.requiredLevel - check.currentYearTotal) > 0 ? '-' : (check.requiredLevel - check.currentYearTotal) }}</td>
        <td>{{ check.remark }}</td>
      </tr>
    </ng-template>
  </p-table>
  <nav aria-label="Page navigation example">
    <ul class="pagination justify-content-end">
 
<li class="page-link">

  <a class="page-link" href="#" aria-label="next">
     <span aria-hidden="true">Previous &raquo;</span>
   </a>
 </li>




      <li class="page-item"><a class="page-link" href="#">1</a></li>
      <li class="page-item"><a class="page-link" href="#">2</a></li>
      <li class="page-item"><a class="page-link" href="#">3</a></li>
      

<li class="page-link">

       <a class="page-link" href="#" aria-label="next">
          <span aria-hidden="true">Next &raquo;</span>
        </a>
      </li>



    </ul>
  </nav>
  </div>
</div>
</section>
