import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { CardModule } from 'primeng/card';
import { MenuModule } from 'primeng/menu';
import { StepsModule } from 'primeng/steps';

import { CommonModule } from '@angular/common';
import { ExtractArrayValue } from '../pipes/extractvalue.pipe';
import { AppComponent } from '../app.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';

@NgModule({
  declarations: [ExtractArrayValue, SidebarComponent],
  imports: [RouterModule, CommonModule, FormsModule, ReactiveFormsModule,
    InputTextModule,
    CalendarModule,
  ],
  exports: [RouterModule, CommonModule, FormsModule, SidebarComponent, ReactiveFormsModule],
})
export class SharedModule {


}
