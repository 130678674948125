import { Component } from '@angular/core';

@Component({
  selector: 'app-mailinglist',
  templateUrl: './mailinglist.component.html',
  styleUrls: ['./mailinglist.component.css']
})
export class MailinglistComponent {
data: any[];

}
