import { Component, OnInit, ViewChild } from '@angular/core';
import { StatisticsService } from 'src/app/statistics/statistics.service';

import { registerables } from 'node_modules/chart.js';
import Chart from 'chart.js/auto';


Chart.register(...registerables);

interface AssetStat {
  name: string;
  total: number;
}

interface Station {
  id: number;
  name: string;
}

interface StationAssetStats {
  totalAsserts: number;
  totalFixedAsserts: number;
  totalCurrentAsserts: number;
  assetsStats: AssetStat[];
}

interface StationItemStat {
  stationId: number;
  name: string;
  total: number;
  assetsStats: StationAssetStats;
}

interface StationStatsResponse {
  totalStations: number;
  stationItemStats: StationItemStat[];
}

@Component({
  selector: 'app-asset-per-station-stats',
  templateUrl: './asset-per-station-stats.component.html',
  styleUrls: ['./asset-per-station-stats.component.css']
})
export class AssetPerStationStatsComponent implements OnInit {

  stationStats: StationStatsResponse;
  @ViewChild('barChart') pieChart: Element;

  constructor(private statisticsService: StatisticsService){

  }

  ngOnInit(): void {
    this.getAllStationStats();
  }

  private getAllStationStats() {
    this.statisticsService?.getStationStats().subscribe((data: any) => {
      console.log(data);
      this.stationStats = data;
      this.createBarChart();
      
    });
  }


  createBarChart() {
    console.log(this.stationStats?.stationItemStats, this.stationStats);
    const stationLabel = this.stationStats?.stationItemStats.map(
      (station) => station.name
    );
    const stationData = this.stationStats?.stationItemStats.map(
      (station) => station.total
    );
    console.log(stationLabel, stationData);
    const numStations = this.stationStats?.stationItemStats.length;
    const bgColorsBasedonData = this.generateRandomColors(numStations);

    const ctx = document.getElementById('barChart') as HTMLCanvasElement;
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: stationLabel,
        datasets: [
          {
            data: stationData,
            backgroundColor: bgColorsBasedonData,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
    });
  }

  generateRandomColors(numColors: number): string[] {
    const colors: string[] = [];
    const saturation = 70; // Higher saturation for more vibrant colors
    const lightness = 50; // Mid-level lightness for balanced contrast

    // Generate distinct colors by rotating the hue
    for (let i = 0; i < numColors; i++) {
      const hue = Math.floor((i * (360 / numColors)) % 360); // Distribute hue evenly
      const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
      colors.push(color);
    }
    return colors;
  }

}
