<section *ngIf="(registerState$ | async) as state" [ngSwitch]="state.dataState">
  <div *ngIf="!state.registerSuccess" class="container">
    <div class="flex justify-center my-10">
      <div class="w-full md:w-1/2 lg:w-1/3 mt-12">
        <div class="bg-white border border-gray-200 rounded-lg shadow-lg overflow-hidden">
          <div class="p-6">
            <div class="text-center mb-6">
              <img src="../../assets/img/logo.png" alt="Logo" class="mx-auto"/>
              <h5 class="card-title mt-4 mb-5">Create an Account</h5>
            </div>
            <form #registerForm="ngForm" (ngSubmit)="register(registerForm)"
                  class="max-w-sm mx-auto justify-content-center">
              <div class="flex flex-row space-between">
                <div class="mb-5">
                  <label for="firstName" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First
                    Name</label>
                  <input type="text" [disabled]="state.dataState === DataState.LOADING" ngModel name="firstName"
                         id="firstName" required
                         minlength="2"
                         class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                         placeholder="First Name" required/>
                </div>
                <div class="mb-5">
                  <label for="lastName" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Last
                    Name</label>
                  <input type="text" [disabled]="state.dataState === DataState.LOADING" ngModel id="lastName"
                         name="lastName" required
                         minlength="2"
                         class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                         placeholder="Last Name" required/>
                </div>
              </div>
              <div class="mb-5">
                <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                <input type="email" id="email" [disabled]="state.dataState === DataState.LOADING" ngModel
                       name="email"
                       class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                       placeholder="name@gmail.com" required/>
              </div>
              <div class="mb-5">
                <label for="password"
                       class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                <input type="password" id="password" [disabled]="state.dataState === DataState.LOADING" ngModel
                       name="password"
                       class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                       required/>
              </div>

              <button type="submit"
                      [disabled]="state.dataState === DataState.LOADING || registerForm.invalid || registerForm.pristine"
                      class="text-white mb-3 bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none w-full focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-blue-800">
                          <span *ngIf="state.dataState === DataState.LOADING" class="spinner-border spinner-border-sm"
                                role="status" aria-hidden="true" style="margin-right: 5px;"></span>
                <span *ngIf="state.dataState === DataState.LOADING">Loading...</span>
                <span *ngIf="!(state.dataState === DataState.LOADING)">Register</span>
              </button>
              <div *ngIf="state.dataState === DataState.ERROR"
                   class="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                {{ state.error }}
                <button type="button" class="btn-close btn-close-white" data-bs-dismiss="alert"
                        aria-label="Close"></button>
              </div>
            </form>
            <div class="flex flex-col items-center justify-center mb-4 text-black">
              <div class="flex justify-center w-full p-6">
                <button routerLink="/login"
                        class="text-black hover:underline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                  <span>Login Now</span>
                </button>
              </div>
              <div class="flex justify-center w-full text-center">
                <button routerLink="/resetpassword"
                        class="text-black hover:underline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                  <span>Forgot password?</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--    <div class="row justify-content-center my-5">-->
      <!--      <div class="col-md-6" style="margin-top: 50px;">-->
      <!--        <div class="card">-->
      <!--          <div class="card-body">-->
      <!--            <div class="text-center">-->

      <!--              <div class="text-center logo">-->
      <!--                <img src="../../assets/img/logo2.png"/>-->
      <!--              </div>-->
      <!--              <h5 class="card-title mt-4 mb-5">Create an Account</h5>-->
      <!--            </div>-->
      <!--            <div *ngIf="state.dataState === DataState.ERROR"-->
      <!--                 class="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">-->
      <!--              {{ state.error }}-->
      <!--              <button type="button" class="btn-close btn-close-white" data-bs-dismiss="alert"-->
      <!--                      aria-label="Close"></button>-->
      <!--            </div>-->
      <!--            <form #registerForm="ngForm" (ngSubmit)="register(registerForm)">-->
      <!--              <div class="form-outline mb-4">-->
      <!--                <label class="form-label" for="firstName">First Name</label>-->
      <!--                <input type="text" [disabled]="state.dataState === DataState.LOADING" ngModel name="firstName"-->
      <!--                       class="form-control" required minlength="2">-->
      <!--              </div>-->
      <!--              <div class="form-outline mb-4">-->
      <!--                <label for="lastName" class="form-label">Last name</label>-->
      <!--                <input type="text" [disabled]="state.dataState === DataState.LOADING" ngModel name="lastName"-->
      <!--                       class="form-control" required minlength="2">-->
      <!--              </div>-->
      <!--              <div class="form-outline mb-4">-->
      <!--                <label for="password" class="form-label">Email Address</label>-->
      <!--                <input type="email" [disabled]="state.dataState === DataState.LOADING" ngModel name="email"-->
      <!--                       class="form-control" required minlength="3">-->
      <!--              </div>-->
      <!--              <div class="form-outline mb-4">-->
      <!--                <label for="password" class="form-label">Password</label>-->
      <!--                <input type="password" [disabled]="state.dataState === DataState.LOADING" ngModel name="password"-->
      <!--                       class="form-control" required minlength="4">-->
      <!--              </div>-->
      <!--              <div class="d-flex justify-content-center mb-4">-->
      <!--                <button-->
      <!--                  [disabled]="state.dataState === DataState.LOADING || registerForm.invalid || registerForm.pristine"-->
      <!--                  type="submit" class="btn btn-primary">-->
      <!--                  <span *ngIf="state.dataState === DataState.LOADING" class="spinner-border spinner-border-sm"-->
      <!--                        role="status" aria-hidden="true" style="margin-right: 5px;"></span>-->
      <!--                  <span *ngIf="state.dataState === DataState.LOADING">Saving...</span>-->
      <!--                  <span *ngIf="!(state.dataState === DataState.LOADING)">Create Account</span>-->
      <!--                </button>-->
      <!--              </div>-->
      <!--            </form>-->
      <!--            <div class="row mb-4">-->
      <!--              <div class="col d-flex justify-content-center">-->
      <!--                <a [ngStyle]="{'pointer-events' : state.dataState === DataState.LOADING ? 'none' : 'auto'}"-->
      <!--                   [routerLink]="['/']">Account Login</a>-->
      <!--              </div>-->
      <!--              <div class="col">-->
      <!--                <a [ngStyle]="{'pointer-events' : state.dataState === DataState.LOADING ? 'none' : 'auto'}"-->
      <!--                   [routerLink]="['/resetpassword']">Forgot password?</a>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>

  <!-- Success screen -->
  <div *ngIf="state.registerSuccess" class="container">
    <div class="row justify-content-center my-5">
      <div class="col-md-6" style="margin-top: 50px;">
        <div class="card">
          <div class="card-body">
            <div class="text-center">
              <h2><i style="margin-right: 5px;" class="bi bi-safe"></i>SecureCapita</h2>
              <h5 class="card-title mt-4 mb-4">{{ state.message }}</h5>
              <i class="bi bi-check-circle-fill" style="font-size: 80px;color: green;"></i>
              <p class="mt-2" style="font-size: 20px;">Please access your email and confirm your account.</p>
            </div>
            <div class="row mb-4">
              <div class="col d-flex justify-content-center">
                <a [routerLink]="['/']">Account Login</a>
              </div>
              <div class="col">
                <a (click)="createAccountForm()" style="color: #0D6EFD; text-decoration: underline; cursor: pointer;">
                  Create another account
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
