
<p-table #dt [value]="data" [rows]="6" [paginator]="true"
[globalFilterFields]="['name', 'country.name', 'representative.name', 'status']"
[tableStyle]="{ 'min-width': '75rem' }" [rowHover]="true" dataKey="id"
currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
<ng-template pTemplate="caption">
  <!-- <div class="flex align-items-center justify-content-between"> -->
<!-- 
    <p-dropdown [options]="stations" [(ngModel)]="selectedStationId" optionLabel="label" optionValue="value"
      (onChange)="onStationChange()" placeholder="Select Station">
    </p-dropdown> -->



    <!-- <span class="p-input-icon-left">
      <i class="pi pi-search"></i>
      <input pInputText type="text" (input)="dt.filterGlobal(getEventValue($event), 'contains')"
        placeholder="Search..." />
    </span>
  </div> -->
</ng-template>

<ng-template pTemplate="header">
  <tr>
    <th class="px-4 py-2">Initiated Date</th>
    <th class="px-4 py-2">Initiated By</th>
    <th class="px-4 py-2">Asset Name</th>
    <th class="px-4 py-2">Reason</th>
    <th class="px-4 py-2">Requested Location</th>
    <th class="px-4 py-2">Current Location</th>
    <th class="px-4 py-2">Status</th>
    <th class="px-4 py-2">Actions</th>
  </tr>
</ng-template>
<ng-template pTemplate="body" let-item>
  <tr>
    <td class="border px-4 py-2" style="white-space: nowrap;">{{ item.date | date:'dd-MM-yyyy' }}</td>
    <td class="border px-4 py-2" style="white-space: nowrap;"> {{ item.initiatedBy ? item.initiatedBy : 'N/A' }}
    </td>

    
    <td class="border px-4 py-2">{{ item.serial }}</td>
    <td class="border px-4 py-2">{{ item.id }}</td>
    <td class="border px-4 py-2">{{ item.serial }}</td>
    <td class="border px-4 py-2">{{ item.requestedStation }}->{{ item.requestedLocation }}</td>
    <td class="border px-4 py-2">{{ item.reason }}</td>
    <td class="border px-4 py-2">{{ item.currentStation }}->{{ item.currentLocation }}</td>
    <td class="border px-4 py-2">{{ item.status }}</td>
    <td>
      
   
  
    </td>
  </tr>
</ng-template>
</p-table>
